.inputContent {
    font-size: 1.8rem;
    font-weight: 500;
    color: var(--font-blue);
    text-align: start;
    --errorColor: #FFA6A8;
}

.inputContent div {
    width: 100%;
    margin-top: 0.8rem;
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 0.4rem
}

.inputContent input {
    padding: 0.8rem 1.6rem;
    border-radius: 0.8rem;
    font-size: 1.8rem;
    color: var(--font-high);
    font-family: 'Manrope', sans-serif;
    outline: none;
    background-color: var(--font-blue);
}

.errorInput input{
    border: 0.2rem solid var(--errorColor);
    box-sizing: content-box;
}

.error, .placeholder {
    margin-top: 0.4rem;
    font-size: 1.4rem;
    font-weight: 400;
}

.error {
    color: var(--errorColor)
}
/* --var-button-primary-color: #6B770D; */

.button {
    width: 100%;
    font-size: 1.6rem;
    padding: 1.2rem 2.4rem;
    cursor: pointer;
    font-weight: bold;
    font-family: 'Caudex', serif;
    background-color: transparent;
    border: none;
    border-radius: 0.8rem;
}

.primary {
    background-color: #FBF9AB;
    color: #3E3C02;
    box-shadow: 0px 0px 40px 0px #FBF8924D;
}

.outlined {
    border: 0.1rem solid var(--font-yellow);
    color: var(--font-yellow);
}

.link {
    width: initial;
    color: var(--link);
    text-decoration: underline;
}

.secondary {
    background-color: transparent;
    color: var(--brand-button);
    font-size: 1.6rem;
}

.button:disabled {
    border: 0.1rem solid var(--font-yellow);
    color: var(--font-yellow);
    cursor: initial;
}

@media only screen and (max-width: 768px) {
    .button {
        font-size: 1.3rem;
    }
}
.headerBg {
    background-color: var(--brand-primary);
    position: fixed;
    top: 0;
    z-index: 30;
    width: 100%;
    backdrop-filter: blur(10px)
}

.headerWrapper {
    padding: 1.6rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    gap: 8rem;
}

.headerWrapper a {
    text-decoration: none;
}

.headerWrapper li {
    font-size: 1.6rem;
    font-family: 'Caudex', serif;
    font-weight: 700 ;
    color: var(--font-blue);
    letter-spacing: 0.15rem;
    display: flex;
    align-items: center;
}

.spacing {
    height: 12.6rem;
}

@media only screen and (max-width: 768px) {
    .headerBg {
        width: 100%;
        position: fixed;
        top: 0;
        z-index: 30;
        background-color: transparent;
    }

    .mobileHeader {
        padding: 0.4rem 1.6rem 0.4rem;
        position: relative;
        background-color: var(--brand-primary);
        display: flex;
        align-items: center;
    }

    .sandwichMenu {
        width: 4.4rem;
        height: 4.4rem;
        position: relative;
        cursor: pointer;
        background-color: transparent;
    }

    .centerBar, .topBar, .bottomBar{
        width: 1.4rem;
        height: 0.2rem;
        position: absolute;
        left: 1.2rem;
        border-radius: 0.3rem;
        background-color: var(--white);
    }

    .centerBar {
        top: 50%;
        transform: translateY(-50%);
    }

    .topBar, .bottomBar{
        width: 2.2rem;
    }

    .topBar {
        top: 1.2rem;
    }

    .bottomBar {
        bottom: 1.2rem;
    }

    .mobileHeader a svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        /* width: 4.2rem; */
    }

    .headerBg ul {
        height: calc(100vh - 6.4rem);
        padding: 1.2rem 0;
        background-color: var(--white);
        display: flex;
        flex-direction: column;
        list-style: none;
    }

    .headerBg a {
        text-decoration: none;
    }

    .headerBg ul li {
        width: 100%;
        padding: 1.6rem 2.4rem;
        font-size: 2rem;
        font-weight: 400;
        color: var(--font-high);
    }

    .toggleSong {
        width: 4.2rem;
        height: 4.2rem;
        position: absolute;
        top: 0.8rem;
        right: 1.6rem;
        background-color: transparent;
        border-radius: 4.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .toggleSong svg {
        width: 2.8rem;
        height: 2.8rem;
        stroke: var(--white);
        fill: #e4e6ff;
    }

    .spacing {
        height: 0;
    }

    .openMenu{
        --top-bar-open-menu-animation: shrink-bar 0.3s ease forwards,
        rotate-bar 0.2s ease forwards, 
        expand-bar 0.3s ease forwards;

        --bottom-bar-animation: shrink-bar 0.3s ease forwards,
        unrotate-bar 0.2s ease forwards, 
        expand-bar 0.3s ease forwards;
        
        --top-bar-delay: 0s, 0.3s, 0.6s;
        --bottom-bar-delay: 0s, 0.3s, 0.6s;
    } 

    .openMenu .topBar {
        animation: var(--top-bar-open-menu-animation);
        animation-delay: var(--top-bar-delay);
    }

    .openMenu .centerBar {
        animation: 0.3s ease shrink-bar forwards;
    }

    .openMenu .bottomBar {
        animation: var(--bottom-bar-animation);
        animation-delay: var(--bottom-bar-delay);
    }

    .closeMenu {
        --top-bar-close-menu-animation: shrink-bar 0.3s ease forwards,
        unrotate-bar 0.2s ease forwards,
        expand-bar 0.3s ease forwards;
    }

    .closeMenu .topBar, .closeMenu .bottomBar {
        animation: expand-bar 0.3s ease forwards;
    }

    .closeMenu .centerBar {
        animation: 0.3s ease expand-center-bar forwards;
    }

    .showMenu {
        animation: 0.3s ease show-menu forwards;
    }

    .hideMenu {
        animation: 0.4s ease hide-menu forwards;
    }

    @keyframes shrink-bar {
        100% {
            width: 0;
        }
    }

    @keyframes expand-bar {
        0% {
            width: 0;
        }

        100% {
            width: 2.2rem;
        }
    }

    @keyframes expand-center-bar {
        0% {
            width: 0;
        }

        100% {
            width: 1.4rem;
        }
    }

    @keyframes rotate-bar {
        30% {
            transform: translateY(0.9rem) rotateZ(0deg);
        }

        100% {
            transform: translateY(0.9rem) rotateZ(45deg);
        }
    }

    @keyframes unrotate-bar {
        30% {
            transform: translateY(-0.9rem) rotateZ(0deg);
        }

        100% {
            transform: translateY(-0.9rem) rotateZ(-45deg);
        }
    }

    @keyframes show-menu {
        0% {
            transform: translateX(-100%);
        }

        100% {
            transform: translateX(0);
        }
    }

    @keyframes hide-menu {
        0% {
            transform: translateX(0);
        }

        100% {
            transform: translateX(-100%);
        }
    }
}
.timerWrapper {
    text-transform: uppercase;
    display: grid;
    grid-template-columns: 29rem 13.9rem 29rem;
}

.timerContainer {
    height: 15.2rem;
    background-color: #ACE7E9CC;
    color: var(--font-dark);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: 'Caudex', serif;
    letter-spacing: 0.2rem;
    font-weight: 700;
}

.timerContainer h3 {
    font-size: 5.6rem;      
}

.timerContainer span {
    font-size: 2.4rem;
}

.days {
    border-radius: 0 0 0 60rem;
}

.minutes {
    border-radius: 0 60rem  0 0;
}

@media only screen and (max-width: 768px) {
    .timerWrapper {
        grid-template-columns: 11.384rem 7.8rem 11.384rem;
        justify-content: center;
    }

    .timerContainer {
        height: auto;
        padding: 1.2rem 0;
    }

    .timerContainer h3 {        
        font-size: 2.4rem;      
    }

    .timerContainer span {
        font-size: 1.2rem;
    }

    .days {
        border-radius: 0 0 0 21.5rem;
    }

    .minutes {
        border-radius: 0 21.5rem  0 0;
    }
}
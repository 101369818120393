:root {
  /* Colors */
  /* --bg-beige: #F6F5F2;
  --brand-button: #6B770D; */
  --brand-primary : #ACE7E933;
  --brand-tag: #F0F3EA;
  --border-default: #B6B6B6;
  --disabled: #6B6D5A;
  --font-high:#424B37;
  --font-medium:#646651;
  --font-blue: #D5FFFB;
  --font-yellow: #FBF9AB;
  --font-dark: #20525D;
  --link: #136BD3;
  --selected-tag: #8CA66F; 
  --white: #ffffff; 
  --success-bg: #20AD68;
  --error: #F56462;

  /* Families */
  --font-family-primary: 'Caudex' serif;
  --font-family-secondary: 'Spirax' serif;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F6F5F2;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

main {
    font-family: 'Caudex', serif;
    background-color: #0F504E;
}

section > div:first-child {
    position: relative;
    text-align: center;
}

h2 {
    font-family: 'Spirax', serif;
    font-weight: 400;
    color: var(--font-yellow);
    font-size: 4.8rem;
}

.sectionWithDivider {
    padding-top: 8rem;
}

.sectionWithDivider h2 {
    padding-top: 7.2rem;
}

/* Hero Section */
.home {
    width: 100%;
    height: 80rem;
    background-image: url('./libs/assets/images/hero-bg.png') ;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.6rem;
    font-family: 'Spirax', serif;
    color: var(--font-blue);
}

.home span, .home h1, .home h3, .home h4 {
    font-weight: 400;
    text-shadow: 0rem 0.4rem .4rem #01574F;
    letter-spacing: 0.2rem;
}

.home span, .home h3 {
    font-size: 2.4rem;
}

.home h1 {
    margin: 2.4rem 0 3.2rem;
    font-size: 7.2rem;
}

.home h4 {
    font-size: 2rem;
}

/* Countdown Section */
.countDown {
    padding-bottom: 12rem;
    background-image: url('./libs/assets/images/count-down-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
}

.countDown h2 {
    margin-bottom: 4rem;
    letter-spacing: 0.2rem;
}

/* Note Section */

.note {
    height: 67.5rem;
    padding: 12rem 0;
    background-image: url('./libs/assets/images/note-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
}

.note > div {
    max-width: 60rem;
}

.note h2 {
    font-size: 4rem;
    line-height: 6.2rem;
    margin-bottom: 3.2rem;
}

.note p {
    margin-bottom: 2.4rem;
    color: var(--font-blue);
    font-size: 1.8rem;
} 

.note a {
    color: var(--font-yellow);
    font-weight: 700;
    text-decoration: none;
}

/* Ceremony Section */
.ceremony {
    padding-bottom: 12rem;
    background-image: url('./libs/assets/images/local-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
}
    
.ceremony img {
    max-width: 80rem;
    margin: 6.4rem 0;
    border-radius: 2.4rem;
}

.ceremonyContent {
    max-width: 60rem;
    margin: 3.2rem auto 4.8rem;
    font-size: 1.6rem;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
    color: var(--font-blue);
    line-height: 3rem;
}

.ceremonyContent b, .linkLotus p {
    font-size: 2.2rem;
    font-family: 'Spirax', serif;
    font-weight: 400;
    letter-spacing: 0.1rem;
}

.ceremonyContent address {
    font-style: normal;
}

.ceremonyContent a {
    color: var(--font-blue);
    text-decoration: underline;
    font-size: 1.4rem;
}

/* Dresscode Section */
.dressCode {
    padding-top: 6.4rem;
    padding-bottom: 12rem;
    background-image: url('./libs/assets/images/dress-code-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.dresscodeContent {
    max-width: 66rem;
    margin: 2.4rem auto 4.8rem;
    color: var(--font-blue);
    font-size: 1.8rem;
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
}

.utils {

    margin-top: 4.8rem;
}

.utils h3 {
    font-size: 3.2rem;
    font-family: 'Spirax', serif;
    color: var(--font-yellow);
    font-weight: 400;
}

.utils p {
    margin: 1.6rem auto 0;
    font-size: 1.8rem;
    font-family: 'Caudex', serif;
    color: var(--font-blue);    
    font-weight: 400;
    max-width: 60rem;

} 

.suggestGrid {
    margin-top: 4.8rem;
    display: grid;
    grid-template-columns: repeat(6, 16.4rem);
    justify-content: center;
    gap: 3.2rem 2.4rem;
    list-style: none;
}

.dressCode .suggestCard img {
    max-width: 16.4rem;
    border-radius: 0.8rem;
}

.suggestCard a {
    text-decoration: underline;
    color: var(--font-blue);
}

.suggestCard span{
    font-size: 1.2rem;
    font-family: 'Caudex', serif;
    margin-top: 0.4rem;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
            line-clamp: 2; 
    -webkit-box-orient: vertical;
}

/* Giftlist section */

.giftListSection {
    width: 100%;
    padding-bottom: 12rem;
    background-image: url('./libs/assets/images/gift-list-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.pixContainer {
    max-width: 60rem;
    margin: 3.2rem auto 0;
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
}

.pixContainer p {
    font-size: 1.8rem;
    font-weight: 400;
    color: var(--font-blue);
}

.giftsContainer {
    max-width: 92rem;
    margin: 6.4rem auto 12rem;
    display: grid;
    grid-template-columns: 29rem 51.4rem;
    column-gap: 11.8rem;
    row-gap: 6.4rem;
    position: initial;
    text-align: initial;
}

.giftList {
    list-style: none;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.4rem;
}

/* Confirm section */
.confirm {
    padding-bottom: 12rem;
    background-image: url('./libs/assets/images/confirm-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.confirmForm {
    max-width: 60rem;
    margin: 6.4rem auto 0;
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
}


@media only screen and (max-width: 768px) {
    h2 {
        font-size: 3.2rem;
    }

    .sectionWithDivider {
        padding: 6.4rem 0;
    }
    
    .sectionWithDivider h2 {
        padding-top: 5rem;
    }

    .home {
        --hero-bg: 80rem;
        height: var(--hero-bg);
        padding: 20rem 1.8rem 0;
        background-image: url('./libs/assets/images/mobile-hero-bg.png') ;
        background-position: center;
        text-align: center;
        justify-content: flex-start;
    }

    .home span, .home h1, .home h3, .home h4, .home h5 {
        font-weight: 400;
        text-shadow: 0rem 0.4rem 0.4rem #01574F;
        letter-spacing: 0.2rem;
    }
    
    .home span, .home h3 {
        font-size: 2rem;
    }
    
    .home h1 {
        margin: 2.4rem 0 3.2rem;
        font-size: 5.2rem;
    }
    
    .home h4 {
        font-size: 1.8rem;
    }

    /* Countdown Section */
    .countDown {
        height: auto;
        background-image: url('./libs/assets/images/mobile-countdown-bg.png');
        background-size: cover;
        /* padding-bottom: 6.4rem; */
    }

    .countDown h2 {
        margin-bottom: 2.4rem;
    }

    /* Note Section */
    .note {
        height: auto;
        padding: 6.4rem 0;
        background-image: url('./libs/assets/images/mobile-note-bg.png');
    }

    .note > div {
        max-width: 35.2rem;
    }

    .note h2 {
        font-size: 2.4rem;
        line-height: 3.1rem;
        margin-bottom: 3rem;
    }

    .note p {
        font-size: 1.6rem;
    }

    /* Ceremony Section */
    .ceremony {
        height: auto;
        background-image: url('./libs/assets/images/mobile-cerimony-bg.png');
    }
    
    .ceremony img {
        margin: 0;
        width: 100%;
        border-radius: 1.5rem;
        padding: 0 2.4rem;
    }

    .ceremonyContent {
        max-width: 32.8rem;
        gap: 2.2rem;
    }

    /* Dresscode Section */
    .dressCode {
        height: auto;
        background-image: url('./libs/assets/images/mobile-dress-code-bg.png');
    }

    .dresscodeContent {
        max-width: 32.8rem;
        margin: 3.2rem auto 4.8rem;
    }

    .dressCode img {
        max-width: 32.8rem;
        margin: 0 auto;
    }

    .utils p {
        max-width: 35.2rem; 
    }

    .suggestGrid {
        grid-template-columns: repeat(2, 16.4rem);
    }

    /* Giftlist section */ 
    .giftListSection {
        background-image: url('./libs/assets/images/mobile-gift-list-bg.png');
        background-size: cover;
    }

    .giftListSection > div {
        padding-bottom: 0 ;
    }

    .pixContainer {
        max-width: 32.8rem;
        margin-bottom: 6.4rem;
    }

    .pixContainer > h2:first-child {
        margin-top: 0;
        margin-bottom: 3.2rem;
    }

    .pixContainer p {
        margin-bottom: 0;
    }

    .giftsContainer {
        grid-template-columns: 35.2rem;
        row-gap: 1.6rem;
        justify-content: center;
        margin-bottom: 0;
        margin-top: 4.8rem;
    }  

    .giftList {
        max-width: 32.8rem;
        margin-top: 0;
        grid-template-columns: repeat(2, 1fr);
        gap: 1.6rem;
    }

    /* Confirm section */
    .confirm {
        padding: 6.4rem 4.8rem 16rem;
        background-image: url('./libs/assets/images/mobile-confirm-bg.png');
        background-position-y: bottom;
        background-position-x: center;
    }

    .confirm button {
        font-size: 1.6rem;
    }

    .confirmForm {
        margin-top: 3.2rem;
    }
}
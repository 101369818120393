.block {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

section .labelContainer {
    text-align: start;
    color: var(--font-blue);
}

.labelContainer h5 {
    font-size: 1.8rem;
    font-weight: 500;
}

.labelContainer span {
    font-size: 1.4rem;
    font-weight: 400;
}
.element {
    position: absolute !important;
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: cover;
}

.horizontalCenter {
    left: 50%;
    right: 50%;
    transform: translate(-50%);
}
.snackbar {
    padding: 1.4rem 2.4rem;
    position: fixed;
    z-index: 999;
    top: 2.4rem;
    left: 50%;
    transform: translate(-50%);
    font-size: 1.4rem;
    font-weight: 700;
    font-family: 'Caudex', serif;
    border-radius: 0.8rem;
    box-shadow: 0px 16px 40px 0px #7090B033;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    animation: 
        0.25s ease showSnackbar,
        0.25s ease hideSnackbar forwards;
}

.theme-success {
    background-color: var(--font-blue);
}

.theme-success span {
    color: #20525D;
}

@keyframes showSnackbar {
    0% {
       transform: translate(-50%, -150%);
    }

    30% {
        transform: translate(-50%, 70%);
    }

    100% {
        transform: translate(-50%, 0);
    }
}

@keyframes hideSnackbar {
    0% {
        opacity: 1;
     }
 
     100% {
         opacity: 0;
     }
}

@media only screen and (max-width: 768px) {
    .snackbar {
        min-width: 25.2rem;
        padding: 1.2rem 1rem;
    }
}
.amountContainer {
    display: grid;
    grid-template-columns: 4rem 3.2rem 4rem;
    gap: 2rem;
    align-items: center;
}

.amountContainer input {
    width: 3.2rem;
    font-size: 2rem;
    font-weight: 500;
    font-family: 'Manrope', sans-serif;
    color: var(--font-blue);
    justify-self: center;
    background-color: transparent;
    cursor: initial;
    text-align: center;
}

.amountContainer input:focus {
    outline: none;
}

.button {
    width: 4rem;
    height: 4rem;
    position: relative;
    border-radius: 50%;
    background-color: var(--font-blue);
    cursor: pointer;
}

.increseButton::after, .increseButton::before, .decreaseButton::after {
    content: "";
    position: absolute;
    width: 1.2rem;
    height: 0.1rem;
    top: 50%;
    left: 50%;
    background-color: var(--font-medium);
    border-radius: 0.1rem;
}

.increseButton::after, .decreaseButton::after {
    transform: translate(-50%, -50%)
}

.increseButton::before {
    width: 0.1rem ;
    height: 1.2rem;
    transform: translate(-50%, -50%)
}

@media only screen and (max-width: 768px)  {
    .amountContainer {
        gap: 1rem;
    }
}
.modalOverlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #000000;
    opacity: 30%;
    z-index: 100;
}

.modalWrapper {
    width: 50rem;
    padding: 2.4rem 4.8rem;
    position: fixed;
    top: 50vh; 
    left: 50vw; 
    transform: translate(-50%, -50%);
    z-index: 101;
    background-color: #4C838F;
    box-shadow: 0px -4px 8px 0px #7090B026;
    border-radius: 1.6rem;
    
}

.modalWrapper h2 {
    font-size: 1.8rem;
    font-family: 'Caudex', serif;
    font-weight: 700;
    margin-top: 0;
    color: var(--font-blue);
}

.modalWrapper button {
    font-size: 1.8rem;
    color: #3E3C02;
}

.successLayout, .bookLayout {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2.4rem;
}

.bookLayout {
    align-items: flex-start;
}

.bookLayout div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.bookLayout p {
    font-family: 'Caudex', serif;
    color: var(--font-blue);
    font-size: 1.8rem;
}

.closeIcon {
    cursor: pointer;
}

@media only screen and (max-width: 768px) {
    .modalWrapper {
        width: 34.3rem;
    }

    .successLayout h2 {
        text-align: center;
    }
}
.cardWrapper {
    width: 29rem;
    position: relative;
    overflow: hidden;
}

.cardBg {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
}

.cardWrapper img {
    min-height: 30rem;
    height: 30rem;
    border-radius: 1.6rem;
}

.cardContent {
    padding-top: 1.6rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.6rem;
    text-align: center;
}

.cardContent a {
    text-decoration: none;
}

.cardWrapper .cardContent h3 {
    max-width: 24.2rem;
    height: 4.4rem;
    color: var(--font-blue);
    font-size: 1.6rem;
    font-weight: 600;
    
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
            line-clamp: 2; 
    -webkit-box-orient: vertical;
}

.cardContent span {
    font-size: 3.6rem;
    font-weight: 700;
    color: var(--brand-button);
}

.disabled {
    opacity: 0.5;
}

@media only screen and (max-width: 768px) {
    .cardWrapper {
        width: 16.4rem;
        border-radius: 0.86rem; 
    }

    .cardWrapper img {
        width: 16.4rem;
        height: 16.1rem;
        min-height: 16.1rem;
    }

    .cardContent {
        padding-top: 0.8rem;
        gap: 0.8rem;
    }

    .cardWrapper .cardContent h3 {
        max-width: 24.2rem;
        height: 3.6rem;
        font-size: 1.3rem;
    }

    .cardContent span {
        font-size: 2rem;
    }
}